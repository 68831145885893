@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap');

:root{
  --primary-black: #07090a;
  --secondary-black: #1e2120;
  --dark-grey: #667075;
  --light-grey: #d3dce0;
  --red: #681b1b;
  --error: #ff4a4a;
  background-color: black;
  --font-family: 'Permanent Marker', cursive;
  
}
.App {  
  background-size:     cover;                   
  background-repeat:   no-repeat;
  background-position: center center; 
  background-attachment: scroll;
  max-width: 100vw;
  max-height: 100vh;
  overscroll-behavior: contain;
  background-image: linear-gradient(to bottom, rgba(255,153,153,0) 0%,var(--primary-black) 45%,black 100%), url(/images/szabolcs-toth-CyuvsE3TVak-unsplash.jpg);
}

/* base styles & title */
body{
  
  background-color: transparent;
  margin: 0;
}
body > iframe {
  pointer-events: none; /* completely needed to stop annoying iframe overlay in dev */
 }
header{
  width: 100%;
  min-height: 60vh;
   display: flex;
  justify-content: center;
  align-items: center;
}
footer{
  font-family: var(--font-family);
  color: var(--light-grey);
  background-color: var(--primary-black);
  min-height: 8vh; 
  font-size: 1vw;
}
footer h2 {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%)
}
.content-body{
  font-family: "Noto Serif";
  min-width: 80vw;
  max-width: 960px;
  width: 95vw;
  margin: auto;
  padding-bottom: 30px;
  
}
.content-body h2 {
  font-family: var(--font-family);
}

.title {
  /* border: #ff4a4a solid 5px; */
  width: inherit;
  height: 300px;
  margin: 0vh 0 70vh 0;
  transform: translate(-0%, 130%)
}
.title h2, h3, img{
  color: var(--light-grey);
  text-align: center;
  font-family: var(--font-family);
  margin: 0;
  float: right;
  height: inherit;
  flex-grow: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  
}
.title h2{
  font-size: 4vw;
  transform: translate(-10%, -40%)
}
.title h3 {
  font-size: 2vw;
  transform: translate(30%)
}
.title img {
  margin: 20px 20px 20px 50px;
  border-radius: 50%;
  width: 20vw;
  height: 20vw;
  min-width: 100px;
  min-height: 100px;
  transform: translate(-200%, -70%)
}

/* image grid styles */
.img-grid{
  margin: 0vw 0 0 2vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2%;
  max-width: 90vw;
  min-width: 35rem;
  /* min-height: 40rem; */
  /* border: #ff4a4a solid 3px; */
}
.img-wrap{
  overflow: visible;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
}
.img-wrap img{
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.navbar {
  width: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  z-index: 1000;
  font-family: var(--font-family);
}

/* Navbar links */
.navbar a {
  float: right;
  text-align: center;
  padding: 2%;
  color: var(--light-grey);
  text-decoration: none;
}
.projectName {
  font-size: 2rem;
  color: var(--light-grey);
  margin: 1% 0 0 3%;
}
#roadmap{
  height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0%;
  left: 40%;
}
.roadmap-wrap {
  
  overflow:visible;
  padding-bottom: 50%;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
}
/* Add responsiveness - will automatically display the navbar vertically instead of horizontally on screens less than 400 pixels */

.token-info{
  display: flex;
  float: right;
  /* border: #ff4a4a solid 4px; */
  padding-bottom: 4rem;
}

.text-box {
  margin: 0px 0px 40px 0;
  color: var(--light-grey);
  text-align: justify;
  font-size: 1.3rem;
}

@media screen and (max-width: 450px) {
  .projectName {
    font-size: 1.5rem;
  }
  .title {
    margin: 0vh 0 62vh 0;
  }
  .title h2 {
  font-size: 9vw;
  transform: translate(-50%, -30%)
  }
  .title h3 {
    font-size: 6vw;
  transform: translate(-50%, -10%)
  }
  .title img {
    margin: 20px 20px 20px 20px;
    width: 40vw;
  height: 40vw;
    transform: translate(-60%, -250%)
  }
  .text-box{
    font-size: 1rem;
    padding: 2%;
  }
  /* image grid styles */
.img-grid{
  margin: 0vw 0 0 0vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2%;
  max-width: 90vw;
  min-width: 5rem;
  /* min-height: 40rem; */
}
.img-wrap{
  overflow: visible;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
}
.img-wrap img{
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.token-info{
  display:block;
  float: none;
  /* border: #ff4a4a solid 4px; */
  padding-bottom: 4rem;
}
footer span{
  font-size: 4vw;
}
}
